import React from "react"
import Layout from "../components/layout"
import Markdown from "react-markdown"
import Cta from "../components/cta"

export const query = graphql`
  query TemplatePageQuery {
    strapiFreeTemplates {
      title
      description
      templateDownloads {
        id
        description
        title
        buttonText
        color
        fileDownload {
          absolutePath
          relativePath
          publicURL
        }
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`

export default function Templates({ data, location }) {
  const seo = { ...data.strapiFreeTemplates.seo, url: location.pathname }

  return (
    <Layout seo={seo}>
      <div className="container mx-auto  px-4 mb-12 lg:px-12 mt-12">
        <h1 className="font-sans font-bold text-4xl md:text-5xl lg:text-6xl mb-10">
          <Markdown
            source={data.strapiFreeTemplates.title}
            escapeHtml={false}
          />
        </h1>
      </div>

      <div className="container mx-auto px-4 mb-12 lg:px-12">
        <Markdown
          className="font-medium markdown green-bullets"
          source={data.strapiFreeTemplates.description}
          escapeHtml={false}
          allowDangerousHtml
        />
      </div>

      <div className="container mx-auto px-4 lg:px-12 mb-12">
        <div className="grid grid-cols-12 sm:gap-12">
          {data.strapiFreeTemplates.templateDownloads.map((value, index) => {
            return <Cta ctaData={value} key={index} />
          })}
        </div>
      </div>
    </Layout>
  )
}
